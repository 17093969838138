import { bindActionCreators } from 'redux';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  registerContainer,
  unregisterContainer,
  registerStarsWidth,
} from '~ratings/store/multi/layout/layout-actions';
import { decorateActionsWithLogger } from '~commons/lib/monitoring';
import { getEnvParams } from '~commons/get-env-params';
import { StoreMulti } from '~ratings/store/multi/store-types';

export const controllerActions = {
  registerStarsWidth,
  registerContainer,
  unregisterContainer,
};

export const buildControllerActions = (flowAPI: ControllerFlowAPI, store: StoreMulti) => {
  const { isDebug, logNetwork } = getEnvParams(flowAPI.controllerConfig.wixCodeApi);
  return decorateActionsWithLogger(
    bindActionCreators(controllerActions, store.dispatch),
    flowAPI.errorMonitor,
    isDebug || logNetwork === 'error',
  );
};
